<template>
    <v-container fluid>
        <v-row class="fill-height">
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-icon>mdi-chair-rolling</v-icon>
                        Lista de Serviços
                        <v-spacer></v-spacer>
                        <v-text-field
                            class="mr-3"
                            append-icon="mdi-magnify"
                            label="Busca"
                            single-line
                            hide-details
                            @keydown.enter="buscar()"
                            @click:append="buscar()"
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            :to="{ name: 'servicesCreate' }"
                        >
                            <v-icon left>mdi-plus-thick</v-icon>
                            Novo Serviço
                        </v-btn>
                    </v-card-title>
                    <v-alert type="warning" outlined v-show="!services"
                    >Nenhum usuário encontrado.
                    </v-alert
                    >
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="services"
                        item-key="id"
                        class="elevation-1"
                        v-show="services"
                    >
                        <template v-slot:item.value="{ item }">
                            {{item.value | money }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                icon
                                :to="{ name: 'servicesEdit', params: { id: item.id } }"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {mask} from "vue-the-mask";

import {SERVICES_GET} from "@/store/actions/service.type";
import {SERVICE_RESET_STATE} from "@/store/mutations/service.type";

export default {
    name: "ListarUsuarios",
    directives: {mask},
    props: {
        source: String
    },

    components: {},
    async beforeRouteUpdate(to, from, next) {
        // Reset state if user goes from /editor/:id to /editor
        // The component is not recreated so we use to hook to reset the state.
        await store.dispatch(SERVICE_RESET_STATE);
        return next();
    },
    async beforeRouteEnter(to, from, next) {
        Promise.all([
            store.dispatch(SERVICE_RESET_STATE),
            store.dispatch(SERVICES_GET)
        ]).then(() => {
            next();
        });
    },
    data: () => ({
        //Lista de Usuários
        pagination: {
            sortBy: "name"
        },
        search: "",
        selected: [],
        headers: [
            {
                text: "Nome",
                align: "left",
                value: "name"
            },
            // { text: "Instituição", value: "instituicao" },
            {text: "Categoria", value: "categoryName"},
            {text: "Valor", value: "value"},
            {text: "Editar", value: "actions", sortable: false}
        ],

        formIsValid: false,

        textRules: [
            v => !!v || "Este campo é obrigatório"
            //v => v > 0 || "Esta campo deve ter um valor maior que zero."
        ],
        cpfRule: [
            v => !!v || "CPF é obrigatório",
            strCPF => {
                if (!strCPF) return false;
                strCPF = strCPF.replace(/\D/g, "");
                let Soma;
                let Resto;
                Soma = 0;
                if (strCPF == "00000000000") return "CPF Inválido";
                let i = 0;
                for (i = 1; i <= 9; i++)
                    Soma =
                        Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                Resto = (Soma * 10) % 11;

                if (Resto == 10 || Resto == 11) Resto = 0;
                if (Resto != parseInt(strCPF.substring(9, 10)))
                    return "CPF Inválido";

                Soma = 0;
                for (i = 1; i <= 10; i++)
                    Soma =
                        Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                Resto = (Soma * 10) % 11;

                if (Resto == 10 || Resto == 11) Resto = 0;
                if (Resto != parseInt(strCPF.substring(10, 11)))
                    return "CPF Inválido";
                return true;
            }
        ],
        emailRules: [
            v => !!v || "E-mail é obrigatório",
            value => {
                if (typeof value !== "undefined" && value.length > 0) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Informe um email válido";
                }
                return false;
            }
        ]
    }),
    methods: {
        //Methods Lista Usuarios
        toggleAll() {
            if (this.selected.length) this.selected = [];
            else this.selected = this.dados.slice();
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
        },
        buscar() {
            // store.dispatch(USER_SEARCH);
            // console.log("buscar");
        },
        //Fim Methods Lista Usuarios

        reset() {
            this.$refs.formUsuario.reset();
        }
    },
    mounted() {
    },
    computed: {
        // ...mapState({
        //   courses: state => state.
        // })

        ...mapGetters(["services", "checkUsers"])
    },
    watch: {}
};
</script>
